<template>
  <v-autocomplete
      ref="autocomplete"
      :items="searchResult"
      :label="searchLabel"
      :loading="isLoading"
      :no-data-text="noResultText"
      :no-filter="true"
      :prepend-icon="icon"
      :prepend-inner-icon="innerIcon"
      :rules="requiredFieldRule"
      :search-input.sync="search"
      @change="onSearchSelection"
      @click="onInputClick"
      hide-no-data
      :item-text="itemText"
      :item-value="itemValue"
      flat
      clearable
      :small-chips="multiple"
      v-model="model"
      :outlined="outlined"
      :dense="dense"
      return-object
      :multiple="multiple"
  >
    <template v-slot:append-item>
      <div
          v-if="!isLastPage"
          v-observe-visibility="{
                callback: visibilityChanged
              }"
          class="d-flex justify-center mb-6"
      >
        <v-progress-circular
            indeterminate
            color="primary"
        />
      </div>
    </template>
    <template v-slot:selection="{ attrs, item, parent, selected }" v-if="!multiple">
      <slot name="select" v-bind:item="item">
        {{ item[itemText] }}
      </slot>
    </template>
    <template v-slot:item="{ index, item }" v-if="!multiple">
      <slot name="list" v-bind:item="item">
        {{ item[itemText] }}
      </slot>
    </template>
  </v-autocomplete>
</template>

<script>
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import _ from 'lodash';
import Api from "../services/Api";

export default {
  name: 'GPAutoComplete',
  props: {
    searchLabel: {
      type: String,
      default: function () {
        return '';
      }
    },
    icon: {
      type: String,
      default: function () {
        return '';
      }
    },
    innerIcon: {
      type: String,
      default: function () {
        return '';
      }
    },
    apiPath: {
      type: String,
      default: function () {
        return '';
      }
    },
    searchValue: [String,Number, Object, Array],
    validation: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    status: {
      type: [String, Boolean],
      default: function () {
        return false;
      }
    },
    sortBy: {
      type: String,
      default: 'name'
    },
    itemText: {
      type: String,
      default: 'name'
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    outlined: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    dense: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    multiple: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },
  data() {
    return {
      isLastPage: false,
      noResultText: 'No result found',
      searchItemLimit: 100,
      searchResult: [],
      isLoading: false,
      term$: new Subject(),
      model: null,
      search: null,
      currentPage: 0,
      requiredFieldRule: [
        v => {
          if (_.isNull(v) && this.validation) {
            return 'This field is required';
          } else {
            return true;
          }
        },
      ],
    }
  },
  watch: {
    search(text) {
      this.term$.next(text);
    },
    searchValue: {
      handler: function (value) {
        this.getValue(value);
      },
      immediate: true
    }
  },
  mounted() {
    this
        .term$
        .pipe(
            //filter(term => term && term.length > 2),
            debounceTime(500),
            distinctUntilChanged()
        )
        .subscribe(term => this.apiSearch(term));
    //this.apiSearch('');
  },
  methods: {
    visibilityChanged(e) {
      e && this.apiSearch(this.search || '', true);
    },
    apiSearch(term = '', newPage = false) {
      const autoComplete = this.$refs.autocomplete;

      if ((autoComplete && autoComplete.selectedItems.length && !term) ||
          (this.search === term && this.search === '' && term === '') &&
          !newPage
      ) {
        return;
      }
      this.search = term;

      this.isLoading = true;
      if (newPage) {
        ++this.currentPage;
      } else {
        this.searchResult = [];
        this.currentPage = 1;
      }
      const path = `/${this.apiPath}?rowsPerPage=50&descending=false&page=${this.currentPage}&sortBy=${this.sortBy}&status=${this.status || ''}&s=${term || ''}`;
      Api.getRequest({endpoint: path})
          .then((response) => {
            if (!response || !response.data || !response.data.data) {
              return;
            }
            this.searchResult = this.searchResult.concat(response.data.data.data);
            this.isLastPage = this.searchResult.length === response.data.data.total;
            setTimeout(() => {
              if (this.$refs && this.$refs.autocomplete && this.$refs.autocomplete.getContent()) {
                this.$refs.autocomplete.onScroll();
              }
            }, 500);
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false));
    },
    onInputClick() {
      this.apiSearch('');
    },

    onSearchSelection(selection) {
      if (!selection) {
        this.$emit('clearSearchResult');
        return;
      }

      this.$emit('selectSearchResult', selection);
    },
    getValue(value) {
      if (_.isObject(value)) {
        this.searchResult = [];
        this.searchResult.push(value);
        this.model = value.id;
        this.isLastPage = true;
        if (this.multiple) {
          this.searchResult = [...value];
          this.model = value;
        }
        return;
      }
      this.resetSelectValue();
    },
    resetSelectValue() {
      this.searchResult = [];
      this.model = null;
    }
  }
}
</script>
