<template>
  <base-popup
      :popup-model="show"
      title="Reseller Order links"
      :valid="!valid"
      :width="55"
      :show-save-button="false"
      @onCancelButtonClick="closeFilesPopupHandler"
  >
    <page-overlay :overlay="isOverlay"/>
    <v-data-table
        :headers="headers"
        :items="details"
        hide-default-header
        hide-default-footer
        :disable-pagination="true"
        class="elevation-1 mb-2 mt-5 link-table"
    >
      <template v-slot:item.site="{item}">
        <v-chip color="primary" class="mt-2" v-if="item.site.site_url"
                @click.prevent.stop="openSite(item.site.site_url)">
          {{ item.site.site_url }}
        </v-chip>
        <v-chip color="primary" v-else>
          {{ item.customEntityName }}
        </v-chip>
        <v-simple-table dense v-if="currentItem.billingType === 'Link Insertion on'">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                Anchor
              </th>
              <th class="text-left">
                URL
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="item.anchorText1">
              <td>{{ item.anchorText1 }}</td>
              <td>{{ item.url1 }}</td>
            </tr>
            <tr v-if="item.anchorText2">
              <td>{{ item.anchorText2 }}</td>
              <td>{{ item.url2 }}</td>
            </tr>
            <tr v-if="item.anchorText3">
              <td>{{ item.anchorText3 }}</td>
              <td>{{ item.url4 }}</td>
            </tr>
            <tr v-if="item.anchorText5">
              <td>{{ item.anchorText5 }}</td>
              <td>{{ item.url5 }}</td>
            </tr>
            <tr v-if="item.anchorText6">
              <td>{{ item.anchorText6 }}</td>
              <td>{{ item.url6 }}</td>
            </tr>
            <tr v-if="item.anchorText7">
              <td>{{ item.anchorText7 }}</td>
              <td>{{ item.url7 }}</td>
            </tr>
            <tr v-if="item.anchorText8">
              <td>{{ item.anchorText8 }}</td>
              <td>{{ item.url8 }}</td>
            </tr>
            <tr v-if="item.anchorText9">
              <td>{{ item.anchorText9 }}</td>
              <td>{{ item.url9 }}</td>
            </tr>
            <tr v-if="item.anchorText10">
              <td>{{ item.anchorText10 }}</td>
              <td>{{ item.url10 }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="link-box">
          <div class="link-view">{{ item.liveLink }}</div>
        </div>
      </template>
      <template v-slot:item.total="{item}">
        ${{ item.total }}
      </template>
      <template v-slot:body.append>
        <tr>
          <td style="text-align: right"><h4>Sub Total</h4></td>
          <td style="text-align: right">${{ currentItem.amount }}</td>
        </tr>
        <tr>
          <td style="text-align: right"><h4>Discount</h4></td>
          <td style="text-align: right">${{ currentItem.discount }}</td>
        </tr>
        <tr>
          <td style="text-align: right"><h4>Grand Total</h4></td>
          <td style="text-align: right">${{ currentItem.totalAmount }}</td>
        </tr>
      </template>
    </v-data-table>
  </base-popup>
</template>

<script>
import BasePopup from "../BasePopup";
import Popup from "../../mixins/Popup";
import SitesMixins from "../../mixins/SitesMixins";
import _ from "lodash";
import PageOverlay from "../../PageOverlay";

export default {
  name: "ReportCounterLinkPopup",
  mixins: [
    Popup,
    SitesMixins
  ],
  components: {
    PageOverlay,
    BasePopup
  },
  data() {
    return {
      selectedItem: 0,
      isOverlay: false,
      details: [],
      headers: [
        {
          text: 'Site Link',
          value: 'site'
        },
        {
          text: 'Total',
          value: 'total',
          align: 'right'
        }
      ],
    }
  },
  watch: {
    currentItem: {
      handler(value) {
        if (!_.isEmpty(value)) {
          this.detailsHandler();
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    closeFilesPopupHandler() {
      this.$emit('closePopupHandler');
    },
    async detailsHandler() {
      try {
        this.isOverlay = true;
        let {data: {data}} = await this.$store.dispatch("salesCounter/counterDetails", this.currentItem)
        this.details = data.items;
        this.isOverlay = false;
      } catch (error) {
        this.isOverlay = false;
      }
    },
  }
}
</script>

<style scoped>

</style>