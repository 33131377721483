<template>
  <div :style="{'max-width':maxWidth}">
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="customDate"
        transition="scale-transition"
        offset-y
        nudge-right="20"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
            :large="large"
            outlined
            label
            v-bind="attrs"
            v-on="on"
            color="primary"
            style="height: 40px"
        >
          <v-icon left>mdi-calendar</v-icon>
          {{selectedDateRangeType || 'Select Date'}}
          <v-icon v-if="selectedDateRangeType" small right>mdi-pencil</v-icon>
        </v-chip>
      </template>
      <v-card class="mx-auto">
        <v-window v-model="step">
          <v-window-item :value="1">
            <v-list>
              <template v-for="(item, i) in options">
                <v-list-item @click="onDateTypeSelect(item)">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"/>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="i + 1 < options.length" :key="i"/>
              </template>
            </v-list>
          </v-window-item>
          <v-window-item :value="2">
            <v-row no-gutters>
              <v-col>
                <v-date-picker
                    v-model="customDate"
                    range
                    no-title
                    scrollable
                    color="primary"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-divider/>
                <v-card-actions>
                  <v-spacer/>
                  <cancel-button @onButtonClick="step=1"/>
                  <apply-button @onButtonClick="onCustomDateTypeSelect(customDate)" text="OK"/>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :value="3">
            <v-row no-gutters>
              <v-col>
                <v-date-picker
                    v-model="customMonth"
                    type="month"
                    no-title
                    scrollable
                    color="primary"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-divider/>
                <v-card-actions>
                  <v-spacer/>
                  <cancel-button @onButtonClick="step=1"/>
                  <apply-button @onButtonClick="onCustomMonthTypeSelect(customMonth)" text="OK"/>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import CancelButton from "../buttons/CancelButton";
import ApplyButton from "../buttons/ApplyButton";
import moment from "moment";
import DateMixins from "../mixins/calendar/DateMixins";

export default {
  name: "GDateSelector2",
  components: {ApplyButton, CancelButton},
  mixins:[DateMixins],
  data: () => ({
    selectedDateRange: {
      startDate: moment().subtract(2, 'month').startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD'),
    },
    selectedDateRangeType: 'This 3 Months',
  }),
}
</script>

<style scoped>

</style>
